.image-uploader {
  position: relative;
  display: inline-block;
}

.image-upload--edit-button {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px;
  border: 2px solid #fff;

  &:hover,
  &:active,
  &:focus {
    background-color: rgba(0, 0, 0, 0.5);
    padding: 10px;
    border: 2px solid #fff;
    outline: none;
    box-shadow: none;
  }
}

.image-uploader:hover .image-upload--edit-button {
  opacity: 1;
}

.replace-image-container {
  position: relative;
  display: inline-block;
}

button.btn-replace-image {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  appearance: none;
  background: rgba(0, 0, 0, 0.5);
  border-color: #ced4da;
  border-radius: 0;
  outline: none;
  box-shadow: none;
  padding-left: 0;
  margin: 0;
  width: 100%;
  padding: 1em;
  transition: all 0.2s;

  &:hover {
    opacity: 1;
    background: rgba(0, 0, 0, 0.5);
    border-color: #525252;
  }
}
