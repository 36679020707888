@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.body-overlay {
  display: none;

  .fading-entered & {
    display: block;
    animation: fadeIn 250ms ease-in-out;
  }
}
.add-content-window {
  position: fixed;
  bottom: 5em;
  left: 15px;
  z-index: 20;
  color: #fafafa;
  display: none;
  .fading-entered & {
    display: block;
    animation: fadeIn 250ms ease-in-out;
  }

  .add-content-window--content {
    position: relative;
    background: #292e33;
    border: 1px solid #292e33;
    padding: 1em;
    border-radius: 5px;
    &:after,
    &:before {
      top: 100%;
      left: 28%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }

    &:after {
      border-color: rgba(68, 176, 120, 0);
      border-top-color: #292e33;
      border-width: 11px;
      margin-left: -11px;
    }

    &:before {
      border-color: rgba(68, 176, 120, 0);
      border-top-color: #292e33;
      border-width: 12px;
      margin-left: -12px;
    }
  }

  .close-window {
    position: relative;
    left: calc(100% - 1em - 20px);
    top: 30px;
    display: inline;
    padding: 1em;
    z-index: 21;

    &:hover {
      cursor: pointer;
      opacity: 0.8;

      > * {
        opacity: 0.8;
      }
    }
  }

  .action-button {
    background-color: transparent;
    border: none;
    padding: 0.5em 0;

    &:hover,
    &:active,
    &:focus {
      color: theme-color("primary") !important;
      background-color: transparent !important;
      outline: none !important;
      box-shadow: none !important;
    }
  }
}
