.device-usage-indicator {
  width: 20px;
  height: 20px;
  border: 4px solid white;
  border-radius: 100%;
  margin-right: 1em;
}

.device-usage-label {
  text-transform: capitalize;
}

.cost-comparison {
  border-bottom: 1px solid #363c43;

  .icon-container {
    font-size: 1.2em;
    width: 30px;
    text-align: center;
    color: #848f99;
  }
}
