$tab-border-color: theme-color("gray");
.tabs {
  border-radius: 0.25rem;
  .nav {
    border-bottom: 0;

    .nav-item a {
      border-right: 1px solid $tab-border-color;
      border-bottom: 1px solid $tab-border-color;
      text-transform: uppercase;
      background: #161921;

      &:last-of-type {
        border-right: 0;
      }

      &.active {
        border-bottom: 0;
        background: none;
        border-top: 1px solid $tab-border-color;

        &:last-of-type {
          border-right: 1px solid $tab-border-color;
        }

        &:first-of-type {
          border-left: 1px solid $tab-border-color;
        }
      }
    }
  }

  .tab-content {
    padding: 2em;
    border: 1px solid $tab-border-color;
    border-top: 0;
  }

  .tab-section-headline {
    color: #545f69;
  }
  .tab-nav-link {
    appearance: none;
    margin: 0;
    width: 100%;

    &:active,
    &:focus {
      outline: none;
      box-shadow: none;
    }

    &.active {
      background: none;
    }
  }
}
