.badge {
  position: absolute;
  top: 0;
}

.dropdown-toggle {
  &:after {
    content: none;
  }

  &:hover {
    .icon {
      animation: tada 1.5s ease infinite;
    }
  }
}

.message {
  text-align: left;
  margin-bottom: 0;
  color: var(--gray);
}

.icon {
  font-size: 18px;
  max-width: 18px;
}

.menu {
  padding: 1em !important;
  background-color: var(--gray-dark) !important;
  min-width: 325px !important;
  font-size: 14px !important;
}

.notification-headline {
  font-weight: 600;
  color: white;
  text-align: left;
  font-size: 1em;
}

.dismiss-button {
  background: none !important;
  padding: 5px !important;
  border-radius: 100% !important;
  color: white;
}

@keyframes tada {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  10%,
  20% {
    -webkit-transform: scale3d(0.95, 0.95, 0.95) rotate3d(0, 0, 1, -10deg);
    transform: scale3d(0.95, 0.95, 0.95) rotate3d(0, 0, 1, -10deg);
  }

  30%,
  50%,
  70%,
  90% {
    -webkit-transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 10deg);
    transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 10deg);
  }

  40%,
  60%,
  80% {
    -webkit-transform: rotate3d(0, 0, 1, -10deg);
    transform: rotate3d(0, 0, 1, -10deg);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
