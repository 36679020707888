$color-pink: #e5476c;
$color-primary: $color-pink;
$color-light: #fff;
$color-white: #fff;
$color-gray-dark: #292e33;
$color-gray: #545f69;
$color-dark: #1f2327;
$color-secondary: #16191c;
$color-green: #5cb200;
$color-purple: #ac63b7;
$color-yellow: #ffb000;
$color-success: #44b078;
$color-blue: #568efb;
$color-warning: #ffb000;
$color-muted: #6c757d;

$enable-responsive-font-sizes: true;
$custom-range-thumb-width: 2rem;
$custom-range-track-bg: #ffb000;
$custom-range-track-border-radius: 0;
$custom-range-thumb-bg: white;
$custom-range-track-box-shadow: inset 0 0.25rem 0.25rem rgba(#000, 0.1);
$custom-range-thumb-active-bg: white;
$custom-range-track-height: 0.35rem;

$card-bg: #292e33;

// Modals
$modal-content-bg: $color-dark;
$modal-header-border-color: $card-bg;

$theme-colors: (
  "primary": $color-primary,
  "pink": $color-pink,
  "light": $color-light,
  "gray-dark": $color-gray-dark,
  "gray": $color-gray,
  "dark": $color-dark,
  "secondary": $color-secondary,
  "green": $color-green,
  "purple": $color-purple,
  "yellow": $color-yellow,
  "success": $color-success,
  "blue": $color-blue,
  "warning": $color-warning,
  "muted": $color-muted,
);
