form,
form.form-container {
  .form-control,
  input[type="text"],
  input[type="number"],
  input[type="tel"],
  textarea,
  select,
  select.custom-select {
    border-color: #212121;
    color: theme-color("light");
    background-color: theme-color("secondary");

    &:disabled {
      background-color: theme-color("secondary");
      color: #212121;
    }

    &:focus {
      color: theme-color("light");
      background-color: theme-color("secondary");
      border-color: theme-color("secondary");
      box-shadow: 0 0 0 0.2rem rgba(229, 71, 108, 0.25);
    }

    &::-webkit-input-placeholder {
      font-style: italic;
    }
  }

  textarea {
    width: 100%;
    min-height: 100px;
  }

  .code-input {
    @extend code;
  }

  .form-control {
    background-color: theme-color("secondary");
    border-color: #212121;
    color: white;
  }

  input[type="submit"] {
    @extend button;
    width: 100%;
    display: block;
  }

  label {
    text-transform: capitalize;
  }

  span.label {
    color: white;
  }

  .input-group-prepend {
    .input-group-text {
      background-color: theme-color("secondary");
      border-color: theme-color("secondary");
    }
    select {
      border-radius: 0;
      background-color: theme-color("secondary");
      border-color: #212121;
      color: white;
    }
  }
}

.enhanced-select input:focus {
  box-shadow: none !important;
}

.input-date-picker {
  position: relative;
  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range {
    background-color: theme-color("primary");

    &:hover {
      background-color: theme-color("primary");
    }
  }
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected {
    background-color: theme-color("primary");

    &:hover {
      background-color: theme-color("primary");
    }
  }

  .react-datepicker-wrapper {
    display: block;
    input {
      @extend .form-control;
      border-radius: 0.25rem;
      border: 1px solid #212121;
      box-shadow: none;
      background-color: theme-color("secondary");
      color: white;
      min-width: 200px;
    }
  }

  .date-picker-icon {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
  }
}
