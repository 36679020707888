.guests {
  display: flex;
}

div.status {
  display: flex;
  align-items: center;
  margin-bottom: 1em;
  text-transform: capitalize;
}

span.status-icon {
  &:before {
    content: "";
    width: 10px;
    height: 10px;
    display: inline-block;
    border-radius: 100%;
    margin-right: 5px;
  }
}

.search-input-dark {
  .input-group-text {
    background: theme-color("secondary");
    border-color: theme-color("secondary");
  }

  input {
    background: theme-color("secondary");
    color: white;
    border-color: theme-color("secondary");
    &:focus {
      background: theme-color("secondary");
    }
  }
}

.guest-list-view-options > .input-group {
  width: auto;
  margin-right: 1em;
  &:last-of-type {
    margin-right: 0;
  }
}

.status-icon.active:before {
  background-color: theme-color("primary");
}

.status-icon.inactive:before {
  background-color: theme-color("gray");
}

.status-icon.upcoming:before {
  background-color: theme-color("primary");
}

.status-icon.pending:before {
  background-color: theme-color("blue");
}

.status-icon.administrator:before {
  background-color: theme-color("primary");
}

.status-icon.manager:before {
  background-color: theme-color("primary");
}

.guest-check-in {
  margin-bottom: 1em;

  p {
    margin-bottom: 10px;
    @include font-size(0.85rem);

    strong {
      display: block;
      font-weight: normal;
      @include font-size(1.4em);
    }
  }
}

.personal-info {
  p {
    margin-bottom: 0;
    @include font-size(0.85em);
    word-wrap: break-word;
  }
}

.guest-name {
  color: white;
  @include font-size(1.5rem);
}

.edit-guest button {
  width: 100%;
}

.guest-block {
  margin-bottom: 3em;
  padding-bottom: 3em;
  border-bottom: 1px solid white;

  &:last-of-type {
    margin-bottom: 0;
    border-bottom: 0;
  }

  & > h3 {
    margin-bottom: 1em;
  }
}

button.add-user {
  background: theme-color("primary");
  text-transform: none;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range {
  background: theme-color("primary");
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background: theme-color("primary");
}

.toggle-switch,
.toggle-switch .toggle-knob {
  transition: all 0.2s ease-in;
}

.toggle-switch {
  height: 32px;
  width: 55px;
  display: inline-block;
  background-color: #bfbfbf;
  margin: 2px;
  margin-bottom: 5px;
  border-radius: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;

  &.active {
    background-color: theme-color("primary");
    border: solid 1px transparent;

    .toggle-knob {
      margin-left: 22px;
    }
  }

  .toggle-knob {
    width: 32px;
    height: 32px;
    margin-left: 0px;
    display: inline-block;
    background-color: #ffffff;
    box-shadow: 0px 3px 5px 0px rgba(145, 97, 87, 0.33);
    border-radius: 100%;
    z-index: 50;
  }
}

.carousel-item > .card {
  max-width: 300px;
  margin: 0 auto;
  border: none;
}

.user-avatar-img {
  border-radius: 100%;

  .card-item--header & {
    max-width: 75px;
  }
}
