@keyframes jingle {
  50% {
    transform: translateY(30px);
  }

  100% {
    transform: translateY(0);
  }
}

.logo:hover #bell {
  animation: jingle 0.35s ease-in-out;
  fill: theme-color("primary");
}

.site-header .logo-container {
  width: 128px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.site-header .logo {
  width: 80px;
  margin: 0 auto;
}
