.hr-card {
  &.card-item .card-item {
    padding: 0;
  }
  .carousel-control-prev {
    left: -35px;
  }

  .carousel-control-next {
    right: -35px;
  }
}
