$sidebar_width: 250px;
$header_height: 80px;

.site-header {
  width: 100%;
  background-color: #16191c;
  height: $header_height;
  position: fixed;
  top: 0;
  z-index: 10;
}

.sidebar-nav {
  .expand-exit-done & {
    max-width: 60px;

    .nav-link .nav-text {
      opacity: 0;
      display: none;
    }
  }
  @include media-breakpoint-up("sm") {
    max-height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    top: $header_height;
    max-width: $sidebar_width;
  }
  padding: 0.5em 0;

  @media screen and (min-height: 580px) {
    padding: 1em 0;
  }

  @media screen and (min-height: 720px) {
    padding: 1.5em 0;
  }
  background-color: #16191c;

  ul.nav {
    flex: 2;
  }

  .current-user .profile-photo {
    border: 2px solid transparent;
    padding: 2px;
    transition: all 0.2s ease-in-out;
  }

  .current-user a:hover .profile-photo,
  .current-user .active .profile-photo {
    border-color: theme-color("primary");
    opacity: 0.9;
  }
}

.nav-item {
  .sidebar-nav & {
    margin-bottom: 0.5rem;
    .nav-link {
      padding: 0.5rem 1.5rem;
      @include font-size(0.7rem);
      @media screen and (min-height: 580px) {
        padding: 0.75rem 1.5rem;
        @include font-size(0.8rem);
      }
      @media screen and (min-height: 720px) {
        @include font-size(0.9rem);
        padding: 1rem 1.5rem;
      }
      width: 100%;
      transform: translateX(0);

      &:hover {
        color: theme-color("light");
        cursor: pointer;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        width: 110%;
        transform: translateX(0);
      }

      // &.active {
      //   background-image: linear-gradient(90deg, #ff9e72, #dc0e47);
      //   border-top-right-radius: 5px;
      //   border-bottom-right-radius: 5px;
      //   width: 110%;
      //   transform: translateX(0);
      // }
    }

    // &.nav-item-logout {
    //   .nav-link.active,
    //   .nav-link:hover {
    //     cursor: pointer;
    //     background-image: linear-gradient(
    //       90deg,
    //       theme-color("secondary") 0%,
    //       #050506 100%
    //     );
    //   }
    // }

    // &.nav-item-dashboard,
    // &.nav-item-settings {
    //   .nav-link.active,
    //   .nav-link:hover {
    //     background-image: linear-gradient(90deg, #ff9e72, #dc0e47);
    //   }
    // }

    // &.nav-item-hotel-rooms {
    //   .nav-link.active,
    //   .nav-link:hover {
    //     background-image: linear-gradient(
    //       90deg,
    //       theme-color("purple") 0%,
    //       #7d2574 100%
    //     );
    //   }
    // }

    // &.nav-item-energy {
    //   .nav-link.active,
    //   .nav-link:hover {
    //     background-image: linear-gradient(90deg, theme-color("green"), #3b6112);
    //   }
    // }

    // &.nav-item-marketing {
    //   .nav-link.active,
    //   .nav-link:hover {
    //     background-image: linear-gradient(90deg, theme-color("blue"), #043c80);
    //   }
    // }
  }
  .nav-link {
    color: theme-color("gray");
    text-align: left;
    transition: all 0.2s ease-in-out;
    @include font-size(0.9rem);

    span {
      display: inline-block;
      &.nav-icon {
        width: 15px;
        height: 15px;
        margin: 0 8px 0 auto;
      }
    }

    svg {
      width: 100%;
      height: auto;
      max-height: 100%;
    }

    &.active {
      color: theme-color("light");
    }
  }
}

nav h1.logo {
  max-width: 100px;
}

.header-dropdown {
  .dropdown-divider {
    border-color: theme-color("gray");
  }
  .dropdown-item {
    &:hover,
    &:focus {
      color: white !important;
      background: none;
    }
  }
}
