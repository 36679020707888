body .kohost-room-control-module {
  &::-webkit-scrollbar {
    display: none;
  }
  .scroll-check::-webkit-scrollbar {
    display: none;
  }
}

.room-lock-control .kohost-room-control-module {
  height: 250px;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: visible;
}

.kohost-room-control-module .tv-remote {
  max-height: 417px;
}
