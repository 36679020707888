:not(div.kohost-room-control-module) {
  .btn,
  .button,
  button {
    text-transform: uppercase;
    letter-spacing: 0.03em;
    border-radius: 5px;
    transition: all 0.2s ease-in-out;
    box-shadow: none;
  }

  .btn-default {
    color: white;
    background-color: theme-color("primary");
  }

  .btn-energy-saver {
    color: white;
    background-color: #44b078;

    &:hover {
      background-color: white;
      color: #44b078;
    }
  }

  .btn-edit,
  .btn-settings {
    background-color: #16191c;
    color: white;
  }
  @keyframes spin {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(90deg);
    }
  }

  .btn-link {
    padding: 0;
    background: none;
    color: theme-color("primary");
    border: none;
    text-transform: none;
    font-weight: normal;

    &:hover {
      background: none;
      color: white;
    }
  }

  .btn-settings {
    color: theme-color("light");
    @include font-size(1.2rem);

    &:hover {
      color: theme-color("primary");
    }
  }
}

.btn-add {
  @include font-size(1rem);
  color: white;
  background: #44b078;
  border-radius: 100%;
  transition: all 0.2s;

  .active &,
  &:hover {
    background: white;
    color: #44b078;
    box-shadow: none;
    svg {
      animation: spin 0.3s;
    }
  }
}
