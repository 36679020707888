.container {
  width: 100%;
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: center;
}

.notification {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2em;
  padding: 0.5em 0.75em;
  background: var(--success);
  border-radius: 0.25rem;
  transition: opacity 350ms, transform 350ms;
}

.notification-entered {
  opacity: 1;
  transform: scale(1);
  @extend .notification;
}
.notification-entering {
  opacity: 0;
  transform: scale(0.9);
  //transform: translateX(0);
  @extend .notification;
}
.notification-exited {
  opacity: 0;
  @extend .notification;
}
.notification-exiting {
  opacity: 0;
  transform: scale(0.9);
  @extend .notification;
}

.button {
  background: none !important;
  border: none !important;
  font-weight: 500;

  &:hover {
    color: var(--dark) !important;
  }
}

.button-dismiss {
  @extend .button;
  padding: 0;
  margin-left: 0.5em;
  font-size: 0.85em;
}

.text {
  margin-right: 1em;
  margin-bottom: 0;
}
