.drag-drop-file-upload {
  border: 2px dashed #525252;
  border-radius: 4px;
  transition: all 0.2s;
  background-color: theme-color("secondary");
  color: #6c757d;

  &:hover {
    color: white;
    border-color: white;
  }

  svg {
    fill: #6c757d;
  }
  &.active {
    color: white;
    border-color: white;
    background-color: rgba($color: theme-color("primary"), $alpha: 0.5);

    svg {
      fill: white;
    }
  }

  .drag-drop-file-upload--content {
    padding: 4em 2em;
  }
  .drag-drop-file-upload--text {
    margin-bottom: 0;
    text-align: center;
  }
}
