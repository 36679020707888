.chart-title {
  @include font-size(1.25rem);
}

.chart-subtitle {
  @include font-size(1rem);
  color: theme-color("primary");
}

.chart-select-wrapper {
  @include font-size(0.9rem);
  &:after {
    font-family: "Font Awesome 5 Free";
    content: "\f0d7";
    font-weight: 900;
    display: block;
    margin-left: 5px;
  }
}

select.chart-select {
  appearance: none;
  background: none;
  color: white;
  border: none;
}

.chart-select-label {
  color: theme-color("gray");
}
