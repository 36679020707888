.device-card {
  font-size: 0.9rem;

  .form-enabled .btn-device-settings {
    opacity: 1;
  }

  .btn-device-settings {
    opacity: 0;
    transition: none;
    padding: 0;
    @include font-size(1.2rem);
    width: 30px;
    height: 30px;

    &:hover {
      background: none;
      border-color: transparent;

      color: theme-color("primary");
    }

    &:focus,
    &:active {
      outline: none;
      background: none;
      box-shadow: none;
      border: none;
    }
  }
}

.device-info-form {
  .input-wrapper {
    margin-bottom: 0.5em;

    input,
    select,
    textarea {
      padding: 5px;
      border: none;
      @extend pre;
      color: theme-color("primary");
      margin-bottom: 0;
      text-align: center;
      &:focus {
        outline: none;
      }

      &:disabled {
        color: theme-color("gray");
      }

      &.device-name {
        text-align: left;
        font-family: "Calibre-R", sans-serif;
        @include font-size(1.2rem);
        padding-left: 0.5em;
      }

      &.device-name:disabled {
        padding-left: 0;
        color: white;
        background: none;
      }
    }
    // select {
    //   padding: 0.75em 0;
    //   height: 37px;
    //   text-align: center;
    //   text-align-last: center;
    //   border-radius: 0;
    //   border: 0;
    //   outline: 1px inset theme-color("secondary");
    //   outline-offset: -1px;

    //   &.device-type {
    //     text-align: left;
    //     text-align-last: left;
    //   }

    //   option {
    //     text-align: center;
    //   }
    // }
  }
}

.room-section {
  .btn-remove-room,
  .btn-add-device {
    opacity: 0;
    background: none;
    border: 0;
  }

  .btn-remove-room:hover,
  .btn-remove-room:hover svg {
    color: theme-color("danger");
  }

  .btn-add-device:hover,
  .btn-add-device:hover svg {
    color: theme-color("success");
  }

  &:hover .btn-remove-room,
  &:hover .btn-add-device {
    opacity: 1;
  }
}
