.edit-integration {
  @extend .edit-guest;
}

.card-item.add-integration-card {
  flex-basis: 100%;
}

.integration-form {
  input[type="submit"] {
    background-color: theme-color("primary");
  }
}

.card-item.integration-card {
  flex-basis: 100%;
}

.add-integration-title {
  margin-bottom: 2rem;
  border-bottom: 1px solid white;
  padding-bottom: 2rem;
}

.integration-form-buttons {
  margin-top: 2rem;
}

.badge-integration-type {
  @include font-size(0.75em);

  .integration-name & {
    @include font-size(50%);
  }
}
