.list {
  font-size: 0.85rem;
  ol {
    background: none;
  }
}

.link {
  a {
    color: white;
  }
}
