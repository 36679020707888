.card-item {
  flex-basis: 25%;
  padding: 1.5em 2em 2em;
  background-color: theme-color("gray-dark");
  border-radius: 0.25rem;
  margin-right: 1em;
  margin-bottom: 1em;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  .card-spinner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
    background: rgba(0, 0, 0, 0.4);
  }

  &.hr-card {
    .card {
      box-shadow: none;
    }
  }
}
.next {
  padding: 97px 40px 0px 64px;
}

.avatar {
  height: 130px;
  width: 130px;
  border-color: #131113;
  border: 2px solid rgb(23, 22, 22);
  border-radius: 70px;
}

.avatar-100 {
  height: 100px;
  width: 100px;
  margin-bottom: 10px;
  border-color: #131113;
}
.font-size-130 {
  font-size: 130px;
}

.font-size-100 {
  font-size: 100px;
}

.card-header {
  .card-title {
    text-transform: uppercase;
    font-size: 1.075rem;
    font-weight: 500;
    &:only-child {
      margin-bottom: 0;
    }
  }
  .card-subtitle {
    font-size: 0.85rem;
    font-weight: 400;
    font-style: italic;
    &:only-child {
      margin-bottom: 0;
    }
  }
}

button.card-item--settings-button {
  background: none;
  color: theme-color("light");
  padding: 0;
  @include font-size(1.2rem);
  width: 30px;
  height: 30px;

  &:hover {
    background: none;
    color: theme-color("primary");
  }
}

.default-avatar {
  width: 50px !important;
  height: auto !important;
}
