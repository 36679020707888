.profile-photo {
  border-radius: 100%;

  .sidebar-nav & {
    width: 50px;
    height: 50px;
    margin-bottom: 0.5em;

    @media screen and (min-height: 580px) {
      width: 55px;
      height: 55px;
    }

    @media screen and (min-height: 720px) {
      width: 60px;
      height: 60px;
    }
  }
}

.user-profile-photo {
  .replace-image-container .image--replaceable {
    max-width: 100px;
  }
  .drag-drop-file-upload {
    .drag-drop-file-upload--content {
      padding: 1em;
      max-width: 150px;
      display: inline-block;
    }
  }
}

.user-info-table {
  width: auto;
  td {
    border: none;
    border: 1px solid #424242;

    &:nth-child(1) {
      font-weight: 500;
    }
  }
}
