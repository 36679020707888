@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700&display=swap");

body {
  font-family: "Poppins", sans-serif;
  background: theme-color("dark");
  color: theme-color("light");
  overflow-x: hidden;
  @include font-size(1rem);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}

h1,
.h1 {
  @include font-size(2rem);
}

h2,
.h2 {
  @include font-size(1.8rem);
}

h3,
.h3 {
  @include font-size(1.6rem);
}

h4,
.h4 {
  @include font-size(1.4rem);
}

h5,
.h5 {
  @include font-size(1.2rem);
}

h6,
.h6 {
  @include font-size(1.1rem);
}

p {
  font-weight: normal;
  strong {
    font-weight: 500;
  }
}

code,
pre {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
    "Courier New", monospace;
  color: theme-color("primary");
  word-break: break-word;
}

.page-title {
  text-transform: uppercase;
  font-weight: 600;
  @include font-size(1.4rem);
}
