.request-windows .nav-item .active .text-muted {
  color: theme-color("light") !important;
}

.ticket-list {
  border-collapse: separate;
  border-spacing: 0 1em;
  background-color: transparent;
  thead th,
  tbody td {
    border-bottom: none;
    border-top: none;
  }

  thead th {
    background-color: #343a40;
  }
}

.request-windows {
  height: 80vh;

  .tab-content,
  .guest-messaging-pane {
    position: relative;
    height: 100%;
  }
}

.select-message {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  font-size: 1.3em;
}

.conversation-header {
  .status {
    margin-bottom: 0;
  }
}

.message-group-label {
  @include font-size(0.85rem);
}

.message--timestamp {
  clear: both;
  display: inline-block;
  @include font-size(0.75rem);
}

.conversation {
  .message {
    margin-right: 0.75em;
  }
  .message:last-child {
    padding-bottom: 50px;
  }
}

.message-search {
  button.clear-search-form {
    padding: 0;
    background: none;
    border: 0;
    &:active,
    &:focus {
      border: 0;
      outline: none;
      box-shadow: none;
    }
  }
}

.ticket-list-header {
  padding: 1em 1.5em;
  p {
    margin-bottom: 0;
  }
}

.ticket-card {
  @include font-size(0.9rem);
  background-color: theme-color("secondary");
  padding: 1em 1.5em;
  border-radius: 6px;
  transition: box-shadow 0.15s ease-in-out;

  &:hover {
    cursor: pointer;
  }

  p {
    margin-bottom: 0;
  }
}

.bg-muted {
  background-color: #292e33;
}

.selected-ticket {
  display: none;
  position: fixed;
  height: 100%;
  width: 50%;
  top: 0;
  right: 0;
  background-color: theme-color("secondary");
  padding: 0;
  z-index: 20;

  .conversation {
    padding-left: 2em;
    padding-right: 2em;
    margin-top: 1em;
  }

  .conversation-header {
    position: relative;
    background-color: #292e33;
    padding: 2.5em 1em 2em;

    button.close {
      position: absolute;
      right: 8px;
      top: 8px;
    }
  }

  .send-message {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: #292e33;
    padding: 0 1em 2em 1em;
  }
}

.selected-ticket-enter-active,
.selected-ticket-exit-active,
.selected-ticket-enter-done {
  display: block;
}

@keyframes slideFromLeft {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
}
.selected-ticket-enter-active {
  animation-fill-mode: forwards;
  animation: slideFromLeft 360ms;
}

.selected-ticket-exit-active {
  animation-fill-mode: forwards;
  animation: slideFromLeft 360ms reverse;
}

.statistic-value,
.statistic-label {
  @include font-size(1rem);

  .text-small {
    font-size: 0.8em;
  }

  span {
    display: block;
    text-align: right;
  }
}

.statistic-sublabel {
  @include font-size(0.9rem);
}
