.main-wrapper {
  margin-left: 0;
  max-width: 2000px;
  .sidebar-nav-wrapper {
    @include make-col-ready();
  }

  .component-wrapper {
    top: 80px;
    left: 180px;
    padding-top: 15px;
    padding-bottom: 15px;
    @include make-col-ready();
    @include make-col(12);
    @include media-breakpoint-up("sm") {
      @include make-col(6.5);
      .structure-type-commercial & {
        margin-left: 15px;
      }
    }

    @include media-breakpoint-up("md") {
      @include make-col(8.5);
    }

    @include media-breakpoint-up("lg") {
      @include make-col(9.5);
    }

    @include media-breakpoint-up("xl") {
      @include make-col(10.5);
    }

    & > .container {
      padding: 0;
    }
  }
}

hr {
  background-color: theme-color("gray");
}

img {
  max-width: 100%;
}
