div.login {
  width: 100%;
  height: 100vh;
  position: fixed;
  overflow: hidden;
}

#login {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.intro-container {
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  .image-mask {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    // background-image: url("../../img/login.jpg");
    background-position: 0% 50%;
    background-size: cover;
  }
}

.page {
  position: relative;
}

h1.logo {
  position: relative;
  @include font-size(2.625rem);
  max-width: 200px;
  margin-bottom: 0.5em;

  img {
    width: 100%;
  }
}

.login-form-container {
  height: 100vh;
}

.form-container,
.login-form-container {
  position: relative;
  max-width: 600px;
  padding: 0 15px;
  margin: 0 auto;

  form {
    position: relative;
    width: 100%;
  }

  .alert {
    width: 100%;
  }

  label {
    display: block;
    width: 100%;
    position: relative;
    margin-bottom: 1em;

    input {
      @include font-size(0.875rem);
    }

    input:focus {
      outline: none;
    }

    input:not([value=""]) ~ .label,
    .react-datepicker-popper ~ .label,
    .react-datepicker-wrapper ~ .label,
    input:focus ~ .label,
    input.valid ~ .label {
      @include font-size(0.625rem);
      top: 1.75em;
      color: #606060;
    }

    .label {
      position: absolute;
      top: 0;
      @include font-size(0.9em);
      left: 1.25em;
      width: calc(100% - 3em);
      top: 50%;
      line-height: 1;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      color: white;
      pointer-events: none;
      -webkit-transition: all 0.2s ease-in-out;
      transition: all 0.2s ease-in-out;
    }

    .validity {
      opacity: 0;
      width: 20px;
      height: 100%;
      background: url("../../img/check-mark.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100%;
      position: absolute;
      top: 0;
      right: 0;
      margin-right: 1em;
    }

    input.valid:not(:focus) ~ .validity {
      opacity: 1;
    }
  }

  input:disabled,
  input.disabled {
    opacity: 0.5;
  }

  input[type="text"],
  input[type="email"],
  input[type="number"],
  input[type="tel"],
  input[type="password"],
  textarea {
    background-color: theme-color("secondary");
    border: none;
    padding: 1.75em 1em 1.25em;
    border-radius: 5px;
    color: white;
    font-weight: 500;
    margin-bottom: 0;
    width: 100%;
    display: block;
    clear: both;
    box-shadow: none;
  }

  input[type="submit"] {
    color: #fff;
    background: theme-color("primary");
    padding: 1em 0.25em;
    font-weight: normal;
    border-radius: 5px;
    width: 100%;
    display: block;
    clear: both;
    border: inherit;

    & + button {
      margin-top: 1em;
    }
  }
  button.btn-cancel {
    background-color: #d8d8d8;
  }

  .row {
    width: 100%;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    background: theme-color("secondary");
    -webkit-box-shadow: 0 0 0 1000px theme-color("dark") inset !important;
    color: black !important;
    -webkit-text-fill-color: black !important;
    -webkit-appearance: none;
  }
}

form fieldset {
  width: 100%;
}

input[type="submit"] + a {
  margin-top: 1rem;
  text-align: center;
  display: block;
  color: theme-color("primary");
}

h1.logo svg {
  height: auto;
  width: 100%;
}

form .alert > p {
  margin-bottom: 0;
}

form .alert a {
  color: inherit;
  text-decoration: underline;
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
  display: block;
}
